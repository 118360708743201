.about{
    width: 60%;
    margin:auto;
    height: 100%;
    margin-top:3rem;
    text-align: left;
    padding:5px;
}
.about h1{
    font-size:3rem;
    font-weight: 600;
}
.about p{
    font-size: 1.5rem;
    /* text-align: left; */
    margin-bottom: 2rem;
}
.about p span{
    font-weight: 600;
}
.about h2{
    font-weight: 600;
}

@media screen and (max-width: 767px) {
    .about{
        width:100%;
    }
}