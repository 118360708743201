/* Add these styles to your CSS/Sass file */
.payment-status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 40px;
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  /* Define color classes */
  .green {
    background-color: rgb(39, 217, 39);
  }
  
  .red {
    background-color: rgb(255, 34, 34);
  }
  
  .blue {
    background-color: orange;
  }
  
  /* Add more classes or adjust styles as needed */
  