
.littleloading {
    padding: 0;
    margin: 0;
    display: flex;
    /* width: 100%; */
    height: 5rem;
    justify-content: center;
    align-items: center;
  }
  .littleloading div{
    width: 2rem;
    height: 2rem;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left-color: rgb(241, 117, 1);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  