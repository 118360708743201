.delete{
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    border:1px solid rgb(199, 0, 0);
    border-radius: 10px;
    padding:2rem;
    background-color: rgba(255,0,0,0.1);
    text-align: left;
}
.btn-delete{
    color:#fff;
    background-color: rgb(199, 0, 0);
    font-size: 1.2rem;
    min-width:fit-content;
    height:fit-content;
    padding: 10px 20px;
}
/* .popup{
    
} */
.btn-close{
    position: absolute;
    bottom:1rem;
    right:1rem;
    margin-top: 2rem;
}
.private-detail{
    text-align: left;
}
.status{
    margin-bottom: 2rem;
}
/* .popup button{
    
} */

.btn-save{
    display: flex;
    justify-content:flex-start;
}

