.release-success{
    width: 100%;
    height: 100vh;
    padding:2rem;
}
.release-success span{
    color:rgb(241, 117, 1);
}
.congo{
    /* margin-top: 2rem; */
    font-size: 2rem;
    font-weight: 600;
}
.sharelink{
    border: 1px solid #ccc;
    /* width:fit-content; */
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    height: auto;
    
}
.sharelink p{
    font-size: 1rem;
    padding:5px;    
    transform: translateY(5px);
    max-width: 70%;
  overflow-wrap: break-word;
  word-wrap: break-word;
} 
.btn-share{
    color:#fff;
    background-color: rgb(241, 117, 1);
    /* padding: 1rem; */
    
}
.success-message{
    margin-top: 2rem;
}
.success-message span{
    color: green;
}
.new-node-btn{
    cursor: pointer; /* Add cursor pointer on hover */

    /* Text color styles */
    color: white; /* Default text color is white */
    text-decoration: none; 
}
.new-node-btn:hover {
    cursor:pointer;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s
  }
@media (max-width: 768px) {
    .sharelink{
        width: 100%;
    }
    .release-success{
        padding:1rem;
    }
}