  .note-details{
    width:100%;
    margin:0;
    padding: 3rem 5% 0 5%;
    min-height:100vh;
    height:fit-content
    /* border: 2px solid black; */
  }
  

  .content{
    /* display: inline-block-block; */
    margin: 0;
    padding: 0;
    word-wrap: break-word;
  }
  .details-header{
    display: flex;
    justify-content: space-between;
  }
  .options-left-icons{
    transform: translateY(2px);
    color:rgb(89, 89, 89);
    font-size: 1.3rem;
  }
  .options-right-icons{
    height: fit-content;
    font-size: 2rem;
    transition: 0.3s ease;
  }
  .details-header p{
    margin: 0;
    padding: 0;
    /* width:60%; */
    /* font-weight: 700; */
  }
  .bx-cog:hover{
    cursor: pointer;
    transform: rotate(30deg);
  }
  .bx-x{
    color:#ccc;
    position: absolute;
    top:0;
    right:0;
    font-size: 2rem;
    cursor: pointer;
    z-index: 9999;
  }
  .detailshare{
    padding:0.5rem;
    margin-right: 0.5rem;
    transition: 0.1s ease;
    border-radius: 50%;
  }
  .detailshare:hover{
    cursor: pointer;
    background-color: #ccc;
  }
  .status-bar{
    text-align: right;
  }
  .status-bar p span{
    font-size: 1.3rem;
    font-weight: 500;
  }
  .flex-container{
    display: flex;
    justify-content: space-between;
  }
  .bx-bookmark, .bxs-bookmark{
    cursor: pointer;
    transition: 0.1s ease-in;
  }
  .right p{
    text-align: right;
  }
  .release-span{
    font-size: 1.3rem;
    font-weight: 500;
  }
  .status-active-text{
    display: inline-block;
    color:rgb(0, 201, 34);
    font-size: 1.3rem;
    font-weight:600;
  }
  .status-released-text{
    display: inline-block;
    color:rgb(255, 123, 0);
    font-size: 1.3rem;
    font-weight:600;
  }
  .status-standby-text{
    display: inline-block;
    color:Blue;
    font-size: 1.3rem;
    font-weight:600;
  }
  .desc{
    position: relative;
    border-radius: 10px;
    margin-top: 4rem; 
    background-color: rgb(245, 245, 245);
    text-align: left;
    padding:20px;
    margin-bottom: 0.6rem;
  }
  .desc p{
    /* padding:20px; */
    font-size: 1.2rem;
  }
  /* .releasenote{
    margin-bottom: 5rem;
  } */
  .fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 25%; 
    width: 75%; 
    padding: 20px;
    /* background-color: #f8f8f8; */
    z-index: 0;
    display: flex;
    /* box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2); */
  }
  
  .btn-bottom-release {
    width: 100%;
    color:#fff;
    font-weight: 600;
  }
  .btn-fullgreen{
    background-color:rgb(0, 201, 34);
  }
  .share-mobile{
    display: none;
  }
  .setting-mobile{
    display: none;
  }
  .updates{
    margin-bottom: 8rem;
  }
  .active-dot{
    display: inline-block;
    background-color: rgb(0, 201, 34);
    width: 15px;
    height: 15px;
    border-radius:50%;
    margin-right: 5px;
  }
  .standby-dot{
    display: inline-block;
    background-color: rgb(54, 121, 255);
    width: 15px;
    height: 15px;
    border-radius:50%;
    margin-right: 5px;
  }
  .released-dot{
    display: inline-block;
    background-color:rgb(255, 123, 0);
    width: 15px;
    height: 15px;
    border-radius:50%;
    margin-right: 5px;
  }
  .options-left{
    text-align: left;
    font-size: 0.5rem;
  }
  .options-left p{
    font-size: 1rem;
  }
  
  @media screen and (max-width: 767px) {
    .desc{
      padding:2rem 0 2rem 0;
      margin-bottom: 0.3rem;
      border-radius: 0;
      width:100%;
    }
    .desc p{
      padding:5% 5%;
    }
    .options{
      /* position: absolute; */
      display: none;
    }
    .fixed-bottom{
      left:0;
      width:100%;
      bottom: 3rem;
    }
    /* .extra-space{
      height: 8rem;
    } */
    
    .share-mobile{
      display:block;
      position: absolute;
      font-size: 1.5rem;
    }
    .setting-mobile{
      display: block;
      position: absolute;
      top:1rem;
      right:1rem;
      font-size: 1.5rem;
    }
  }
  