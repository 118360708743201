
.checkout-container {
  text-align: left;
  height: 100%;
  width: 100%;
  margin:0;
}
.payment-methods{
  padding:5rem 5% 5% 8%;
}
.payment-methods h1{
  color:rgb(241, 117, 1);
}
.checkout-form {
  padding: 5%;
  padding-top: 5rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.price-line{
  display: flex;
  justify-content: space-between;
}
.price,.totalamount{
  font-weight: 800;
}

.payment-success {
  width: 400px;
  background-color: #e0f2e9;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pay-btn{
  position: relative;
  background-color: rgb(241, 117, 1);
  color: white;
  border: none;
  padding: 10px 20px;
  min-width: 5rem;
  min-height:3rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}


button{
  position:relative;
}

.pay-btn:hover {
  background-color: rgb(255, 125, 3);
}
.btn-loading::after {
  content: "";
  position: absolute;
  /* padding: 10px 20px; */
  width: 2rem;
  height: 2rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
      transform: rotate(0turn);
  }

  to {
      transform: rotate(1turn);
  }
}

/* user info */

.user-table {
  border: 1px solid #ccc; /* Add border around the whole container */
  padding: 10px; /* Add some padding to the container */
}

.user-table {
  width: 100%;
  margin-bottom: 3rem;
}



.user-table td {
  padding: 10px; /* Add spacing between cells */
  border-bottom: 1px solid #ccc;
}



.user-table strong {
  font-weight: bold; /* Make the labels bold */
}

/* Optional: Style the table header */
.user-table th {
  padding: 10px;
  text-align: left;
  background-color: #f2f2f2;
}

/* inputs */
#razorpay{
  display: block;
}


/* payment options*/

.payment-options{
  border:1px solid #ccc;
  /* margin-bottom: 10rem; */
  /* padding: 1rem;  */
}
/* .option{
  display: flex;
  flex-direction: row;
  gap: 20px;
} */
.payment-info-container{
  background-color: #ededed;
  display: flex;
  height:10rem;
  justify-content: center;
  align-items: center;
}

.payment-info{
  width:80%;
  
}
.radio-container{
  display: flex;
  padding:0.8rem;
  border-bottom: 1px solid #ccc;
}
.option label{
  color: #000000;
  font-size: 1.3rem;
  font-weight: 500;
}
@media (max-width: 768px) {
  .pay-btn, .btn-loading{
    width: 100%;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .checkout-form{
    padding-top:0;
  }
  .payment-methods{
    padding: 5%;
  }
}