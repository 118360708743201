.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color: #000;
    cursor: pointer;
    /* Add additional styles as needed */
  }
  
.popup-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 1;  */
    margin-top: 3rem;
    transition: opacity 0.3s ease-in-out;
  }
  
  .blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    /* z-index: 1; */
  }
  
  .signup-popup {
    /* border: 2px solid red; */
    position: relative;
    width: 35%;
    height: fit-content;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    margin-top: 3rem;  
    margin-bottom: 3rem;  
    display: flex;
    flex-direction: column; 
    /* justify-content: center;
    /* align-items: center; */
  }
  .signup-popup h2{
    /* text-align: left; */
    margin-left: 20px;
    margin-bottom: 2rem;
    font-weight: 700;
  }
  .signup-popup h2 span{
    color: rgb(249, 134, 27);
  }
  .signup-popup form{
    width:100%;
  }
  .popup-open {
    overflow: hidden;
    opacity: 1; /* Set opacity to 1 to show the pop-up */
  }

  .popup-heading{
    margin-bottom: 10%;
  }

  .popup-btn{
    width: 80%;
    border: 1px solid #2c2c2c;
    height:50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    
    margin-bottom:2rem;
    transition: box-shadow 0.3s;
  }
  .bxl-google{
    font-size: 2rem;
  }
  .popup-btn:hover{
    box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.3); 
  }

  .createnew{
    font-size: 1.2rem;
  }
  .createnew span{
    font-weight: 600;
    color: rgb(249, 134, 27);
  }

  /* //signin form */
 
form {
    display: flex;
    flex-direction: column;
  }
  
  .signinform input[type="email"],
  .signinform input[type="password"] {
    /* height:30%; */
    padding: 8px;
    border: none;
    border-bottom: 2px solid rgb(249, 134, 27);
    width: 80%;
  }
  .signinform input[type="password"] {
    font-family: Verdana;
  }
  input[type="email"]::placeholder,
  input[type="password"]::placeholder {
    font-family: 'Montserrat', sans-serif;
  }
  .signinform input[type="email"]:focus,
  .signinform input[type="password"]:focus {
  outline: none;
  border-bottom-color: rgb(255, 129, 11);
  }

  
/* input[type="email"]::placeholder,
input[type="password"]::placeholder {
  color: rgb(123, 123, 123); 
} */
  
  .btn-submit{
    padding: 10px;
    background-color: rgb(249, 134, 27);
    color: #fff;
    font-weight: 600;
    border: 2px solid rgb(249, 134, 27);
    border-radius: 5px;
    cursor: pointer;
    width: 80%;
    min-height: 3rem;
    margin: auto;
    transition: box-shadow 0.3s;
  }
  .btn-submit:hover{
    box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.3); 
  }
  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .divider-line {
    width: 35%;  
    margin: 0 10px;
    border: none;
    border-top: 1px solid #777;
  }
  
  .divider-text {
    font-weight: bold;
    color: #777;
  }
  .error-text {
    color: red;
    font-size: 12px;
    width: 80%;
    margin: auto;
    text-align: left;
    margin-bottom: 25px;
    /* padding: 8px; */
  }
  .add-margin{
    margin-bottom:25px;
  }
  .forgot-password{
    width:80%;
    margin:auto;
    margin-bottom: 1rem;
    display: flex;
    justify-content:flex-end;
  }
  @media screen and (max-width: 767px) {
    .signup-popup{
      width: 100%;
      height:100%;
      margin:0;
      padding:0;
    }
    .blur-background{
      background-color: #fff;
    }
    .signinform input[type="email"],
  .signinform input[type="password"] {
    width: 90%;
  }
  .popup-btn, .btn-submit{
    width: 90%;
  }
  .divider-line{
    width: 40%;
  }
  .forgot-password{
    width: 90%;
  }
   }