.home{
    width:100%;
    /* text-align: left; */
}
.main{
    /* font-weight: 1000; */
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: fit-content;
    width:100%;
    /* text-align: left; */
    /* color:#fff; */
    /* background-color: rgba(241, 117, 1,1); */
    font-family: 'Russo One', sans-serif;
}
.landingpara{
    width: 70%;
    /* font-family: 'MoKklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
    letter-spacing: 1px;
}
.home-image-1 {
    width:90%;
    border: 2px solid rgba(241, 117, 1,1);
    border-radius: 10px;
    /* height: 70%; */
  }
  .home-image-2 {
    width:90%;
    border: 2px solid rgb(241, 117, 1);
    border-radius: 10px;
    /* height: 70%; */
  }
.main-1{
    min-height: 100vh;
    height: fit-content;
    width:100%;
    margin: 0;
    padding: 0 1rem 0 1rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-2{
    min-height: 100vh;
    height: fit-content;
    width:100%;
    margin: 0;
    padding: 0 1rem 0 1rem;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.para-1{
    font-size: 3rem;
    font-weight: 600;
    z-index: -1;
    font-family: 'Russo One', sans-serif;
    /* color: #fff; */
}
.leftside{
    /* max-width: 66%; */
    /* overflow: hidden; */
    height: 100%;
    text-align: center;
    /* overflow: hidden; */
}
.rightside{
    /* overflow: hidden; */
    height: 100%;
    text-align: center;
}
.leftside p, .leftside h2{
    text-align: left;
}

.rightside p, .rightside h2{
    text-align: left;

}
.rightside p{
    font-size: 1.3rem;
    font-weight: 200;
}
.leftside p{
    font-size: 1.3rem;
    font-weight: 200;
}
.leftside h2, .rightside h2{
    margin-top: 4rem;
}
.joinus{
    flex-direction: column;
    
    margin: auto;
}
.joinus-para{
    width:70%;
}
.btn-homepage{
    background-color: rgb(241, 117, 1);
    color:#fff;
    padding:0.5rem;
    min-width:20vw;
    width: fit-content;
    font-size: 1.5rem;
    border-radius: 100px;
    transition: box-shadow 0.3s;
    margin-top: 0;
}

.btn-homepage:hover{
    box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.3); 
}
@media screen and (max-width: 767px) {
    .landingpara{
        width:100%;
    }
    .home{
        text-align:center;
    }
    .main{
        font-size: 2.5rem;
        max-width:90%;
        margin: auto;
        text-align: left;
    }   
    .main-2, .main-1 {
        display: flex;
        flex-direction: column;
    }
    .main-1 .rightside {
        order: -1;
    }
    .btn-homepage{
        width:50vw;
    }
    .home-image-1 {
        /* margin-top:3rem; */
        width:100%;
        height: 100%;
        margin-top:1rem;
        /* height: 50vh; */
      }
      .home-image-2 {
        /* margin-top:3rem; */
        width:100%;
        height: 100%;
        /* height: 50vh; */
      }
      
      .joinus-para{
        font-size: 2.5rem;
        width:100%;
        margin-bottom: 0;
        /* text-align: left; */
      }
      .leftside h2{
        margin-top: 3rem;
      }
      .rightside h2{
        margin-top:3rem;
      }
      .joinus{
        align-items: flex-start;
      }
}
