
/* .notes-container{
  width: 100%;
  display: flex;
  flex-direction: row;
}  */
.top-right-container {
    position: fixed;
    display: flex;
    align-items: center;
    max-width: fit-content;
    top: 1rem; 
    right: 3rem;
    z-index: 999;
  }
.rounded-container{
    display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid rgb(134, 134, 134);
  /* border-radius: 10%; */
  margin:20px;
  padding: 0 0 0 5px;
}

  .rounded-image {
    min-width: 20px;
    min-height: 20px; /* Adjust the size of the image as desired */
    margin-right: 10px;
    border-radius: 50%;
    background-color:rgb(241, 117, 1) ;
    color:#fff;
    font-weight: 600;
    font-size: 0.8rem;
  }
  .btn-orange{
    background-color: rgb(241, 117, 1);
    color:#fff;
    text-decoration: none;
    border: 1px solid #fff;
  }
  .btn-orange:hover{
    color:#fff;
  }
  .text-white{
    color:#fff;
  }
  .totalposts{
    text-align: left;
    margin-left: 2rem;
  }
  .totalposts p{
    margin-top:8rem;
    font-size: 3.5rem;
    font-weight: 800;
  }
  .notes{
    margin:0;
    width:100%;
    display: flex;
    flex-wrap: wrap;
    /* margin-top:8rem; */
  }
  .single-note{
    margin:0;
    padding:0;
  }
  .number{
    padding:0 1rem 0 0;
  }
  .plusicon{
    font-size: 1rem;
    background-color: rgb(241, 117, 1);
    color:#fff;
    padding:5px;
    margin: 0 0 0 0;
  }

  .pagination-controls{
    display: block;
    margin: auto;
    margin-bottom: 3rem;
  }
  .abled{
    color:rgb(241, 117, 1);
    padding:0.5rem;
  }
  .disabled{
    color:#ccc;
    padding:0.5rem;
  }

 @media screen and (max-width: 767px) {
  .top-right-container{
    top: 1rem;
    right: 0.1rem;
  }
  .totalposts{
    margin-left: 2%;
  }
  .pagination{
    /* background-color: red; */
    margin-bottom: 3rem;
  }
 }