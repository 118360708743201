.update{
    display: flex;
    justify-content:flex-end;
}
.update button{
    margin-right: 0;
}
.reaching{
    text-align: left;
    font-size: 2rem;
}