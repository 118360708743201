.saved{
        margin:0;
    width:100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
}
.savedheading{
    display: none;
    font-weight: 600;
}
@media screen and (max-width: 767px) {
    .savedheading{
        display: block;
    }
    .saved{
        margin-top: 1rem;
    }
  
   }