
/* end demo only */
.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 50px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: #fff;
}
.bottom-nav-icons{
  font-size: 2rem;
  transform: translateY(4px);
  margin-right: 1rem;
  color:#000;
}
.mobile-bottom-nav__item {
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon-active .bottom-nav-icons{
  color:rgb(241, 117, 1);
}
.mobile-bottom-nav__item-content {
  display: flex;
  flex-direction: column;
}
