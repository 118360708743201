.privacy-policy{
    width: 60%;
    margin: auto;
}
.privacy-policy h2, .privacy-policy p{
    text-align: left;
    letter-spacing: 1px;
    font-weight: 600;
}
.privacy-policy h1, .privacy-policy h2{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
@media screen and (max-width: 767px) {
    .privacy-policy{
      width: 95%;
    }
  }