.share{
    position: relative;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    padding:0.5rem;
    text-align: left;
    /* color: rgb(255, 255, 133) */
}
.share h2{
    text-align: left;
}
.share p{
    font-size: 1.3rem;
}
.btn-copy, .close-btn {
    padding: 0.5rem 1rem 0.5rem;
    /* font-size: 1.2rem; */
    /* position: absolute; */
    /* right:0.3rem  ; */
    cursor: pointer;
    background-color: #ececec;
    border: none;
    border-radius: 20px;
    
}
.btn-copy:hover{
    background-color: #dcdcdc;
}
.share i{
    transform: translateY(3px);
    margin-right: 0.5rem;
}
.share i:hover{
    cursor: pointer;
    background-color: #ccc;
    border-radius: 50%;
}
.button-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.close-btn{
    color:#fff;
    background-color: rgb(241, 117, 1);
}

@media screen and (max-width: 767px) {
    .share{
        overflow-x: hidden;
    }
  
   }