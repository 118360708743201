.vertical-navbar {
    /* position: fixed; */
    background-color: #ffffff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-right: 1px solid rgb(206, 206, 206);
    height:100vh;
    padding-top:5%;
    /* margin:2% */
  }
  
  .navbar-nav-vn {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-item-vn {
    height:5rem;
    /* padding: 16px; */
    font-size: 1.3rem;
  }
  
  .nav-link-vn {
    display: block;
    padding: 10px;
    color: #000000;
    margin-right:5%;
  }
  .nav-link-vn:hover {
    color: black; /* Set the text color to the same color as the default */
    text-decoration: none; /* Remove the underline on hover */
    background-color: rgb(233, 233, 233);
    border-radius: 8px;
  }
  .active{
    color:#000;
    background-color: rgb(233, 233, 233);
    border-radius: 8px;
    font-weight: 600;
    /* transition: 0.5s ease-in; */
  }
  /* .vertical-container{
   
  } */
  .brand{
    padding: 20px;
    text-align: left;
    color:rgb(241, 117, 1);
    text-decoration: none;
  }
  .brand-link{
    text-decoration: none;
  }
  .brand-link:hover{
    text-decoration: none;
  }

/* box-icon{
  color: red;
} */
.app-icons{
  /* color:red; */
  /* background-color: aqua; */
  font-size: 2rem;
  transform: translateY(4px);
  margin-right: 1rem;
}