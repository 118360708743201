.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Makes the card container take up the entire viewport height */
  }
  .reset-card{
    width:40%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  .password-reset-alert{
    color:red;
    text-align: left;
  }
  .reset-form{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom:2rem;
  }
  .reset-form input[type="email"] {
    padding: 8px;
    border: none;
    border: 2px solid rgb(249, 134, 27);
    width: 100%;
  }
  .reset-form input[type="password"] {
    padding: 8px;
    border: none;
    border: 2px solid rgb(249, 134, 27);
    width: 100%;
  }
  .reset-form label{
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    .reset-card{
        width:100%;
        height:100%;
    }
    
  }