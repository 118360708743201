.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 50%;
    height:80%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    height: fit-content;
    max-height: 80%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
    overflow: auto;
  }
  
  .popup p {
    margin-bottom: 1rem;
  }
  
  .popup {
    margin-right: 10px;
    /* font-size:1.2rem; */
  }
  .popup h2{
    margin-bottom:1rem;
  }
  .popup span{
    font-size: 1.3rem;
  }
  .release-confirm{
    margin-top: 3rem;
    font-size: 1.2rem;
  }
  .btn-release{
    margin:0.5rem;
  }
  #releasetext{
    width: 100%;
    height: 10rem;
    /* resize: none; */
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px;
  }
  .popup label{
    display: flex;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 767px) {
    .popup{
      width: 100%;
      max-height: 100%;
      height:100vh;
      margin:0;
      /* padding:0 */
    }
   }