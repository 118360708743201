 select:focus {
    outline: 3px solid rgb(241, 117, 1);
    /* Add any other styles when the dropdown is focused */
  }
  .note-bottom .save-icon{
    font-size: 1.6rem;
    transition: 0.1s ease-in;
  }
  
  .note-bottom .save-icon:hover{
    cursor: pointer;
    transform: scale(1.2);
  }
  .post-saved{
    color:black
  }
  .create-date{
    transform: translateY(8px);
  }
  .update-indicator{
    position: absolute;
    top:1rem;
    left: 1rem;
    padding:0.3rem;
    background-color: aliceblue;
    padding: 0 1rem;
    border-radius: 10px;
    color: blue;
    font-weight: 600;
    font-size: 0.8rem;
   }