.contact{
    min-height:100vh;
    width:60%;
    margin: auto;
    margin-top:5rem;
    
}
.contact .row{
    text-align: left;
}
.contact h1{
    font-size: 4rem;
}
.contact p{
    font-size:1.3rem;
}
.contact p span{
    font-weight: 800;
}

@media screen and (max-width: 767px) {
    .contact{
        width: 100%;
    }
  }