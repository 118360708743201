.payment-table{
    /* borderCollapse: collapse; */
     width: 90%;
     border:1px solid #c0bfbf;
     padding:2%;
     margin-right: 50px;
     margin: auto;
}
.table-header{
    border-bottom: 1px solid #ddd;
    background: #f2f2f2;
    padding: 8px;
}