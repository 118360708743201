.account-page {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    padding: 20px;
    font-size: 1rem;
  }
  .account-page h2{
    font-weight: 600;
  }
  .details {
    margin-top: 10%;
  }
  
  .account-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .account-item>p {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 100;
  }
  
  .account-item .col-lg-4 {
    flex-basis: 100%;
  }
  .btn-edit{
    color:rgb(8, 8, 8);
    font-weight: 100;
  }
  .btn-logout{
    background-color:#e7e7e7;
    font-size: 1.2rem;
  }
  /* .payment-details{
    margin-top: 4rem;
    display: flex;
    justify-content: flex-start;
  } */
  .logout, .payment-details{
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
  }
  .btn-logout:hover{
    background-color:#d4d4d4;
  }
  
  
  @media screen and (max-width: 767px) {
    .account-page{
      padding:1rem;
      padding-top: 3rem;
    }
    .account-item{
      align-items: flex-start;
      max-width: 100%;
    }
    .account-item>p {
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
    }

  }
  