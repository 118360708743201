.mynotes{
    margin: 0;
    position: relative;
    display: flex;
    flex-wrap:wrap;
    width: 100%;
    min-height: 100vh;
} 
.user-portal{
  display: flex;
  flex-direction: column;
  justify-content: center; 
  width: 100%;
  margin:0;
  padding: 0;
  margin-left: 25%;
}
.v-nav{
  position: fixed;
}

@media screen and (max-width: 767px) {
  .v-nav{
    display: none;
  }
  .user-portal{
    margin: 0;
  }
  .mynotes{
    text-align: center;
  }
}

