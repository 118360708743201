.search{
    width:100%;
    min-height: 100vh;
    height: fit-content;
    padding: 5% 0 0 0;
    margin: auto;
}
.searchoptions{
    width:50%;
    /* padding: 0 2% 0 2%; */
    display:flex;
    align-items: flex-start;
    margin: auto;
}
#searchbar{
    margin-bottom: 2rem;
    height: 3rem;
    margin: 0 1rem 0 2rem;
    width:70%;
    /* border-radius: 1000px; */
}
#searchbar:focus{
    outline: 3px solid rgb(241, 117, 1);
}
.search-btn{
    min-height: 3rem;
    background-color: rgb(241, 117, 1);
    color:#fff;
    margin: 0 1rem 0 0;
    width:30%;
    font-size: 1.3rem;
    font-weight: 500;
}
.dropdown-search{
    min-height: 3rem;
    margin: 0 1rem 0 0;
}
.nomatch{
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nomatch p{
    font-size: 4rem;
    font-weight: 600;
    color:#dbdbdb;
}
.single-note{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .searchoptions{
        width:100%;
    }
    .loadbtn{
        margin-bottom: 5rem;
    }
    .loadbtnnotavail{
        margin-bottom: 3rem;
    }
   }