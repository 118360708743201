
.loading {
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
.loading div{
  width: 4em;
  height: 4em;
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-left-color: rgb(241, 117, 1);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

