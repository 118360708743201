.homepage{
  background-color: rgb(255, 255, 255);
}
.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  min-height: fit-content;
  width: 90%;
  margin: auto;
  padding:5rem 0;
  text-align: left;
}

.main-section {
  text-align: left;
  width:40%;
}

.dmyor {
  font-family: 'Russo One', sans-serif;
  font-size: 4rem;
}

.opening-para{
  font-family: 'Russo One', sans-serif;
  text-align: left;
}

.writer {
  color: rgba(241, 117, 1, 1);
}

.motto {
  text-align: left;
  font-weight: 700;
  width: 80%;
  line-height: 1.8rem;
}

.btn-explore {
  padding: 1rem 1.5rem;
  border-radius: 50px;
}

.video-container {
  width: 60%;
  overflow: hidden;
  text-align: center;
}

.video-container video {
  width: 40%;
  height: auto;
  border: 2px solid rgba(241, 117, 1, 1);
  border-radius: 10px;
  /* margin-left: 6rem; */
  margin-right: -12rem;
}

@media screen and (max-width: 767px) {
  .landing-page {
    width: 90%;
    text-align: left;
  }

  .video-container {
    width: 100%;
  }
  .main-section{
    width: 100%;
  }
  .video-container{
    width: 100%;
  }
  .video-container video{
    width: 100%;
    margin: 0;
    border: 3px solid rgba(241, 117, 1, 1);
    border-radius: 20px;
    /* border: none; */
  }
  .landing-page{
    flex-direction: column-reverse;
  }
  .motto{
    width:100%;
  }
}
