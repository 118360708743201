.navbar{
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.2);
    position: fixed;
    display: flex;
    justify-content: space-between;
    width:100%;
    z-index: 999;
}
.custom-navbar{
    min-height: 10vh;
}
.bg-color{
    background-color: rgb(241, 117, 1);
}
.navbar-brand{
    color: aliceblue;
    font-size: 1.5rem;
    font-weight: 600;
}
.navbar-nav{
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-nav .nav-link {
    margin: 0.5rem; 
    font-size: 1.3rem;
    font-weight: 600;
    text-decoration: none;
  }
  .nav-item:hover{
    background-color: rgba(255, 123, 0, 0.888);
  }
  .nav-link:hover{
    text-decoration: none;
  }
  .btn-transparent {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    margin:0.3rem;
  }
  .outlet{
    width: 100%;
    min-height:100%;
    height:fit-content;
    padding-top: 3.8rem;
    
  }

  @media screen and (max-width: 767px) {
    .buttons{
      margin: auto;
    }
    .signin-btn-navbar{
      margin: auto;
      /* display: none; */
    }
  }