.note {
  position: relative;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.2);  padding: 10px;
  margin: 20px; 
  
  min-height: fit-content;
  width: 100%;
}
.details-header p{
  font-size: 2rem;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.view-fullpage{
  position: absolute;
  top:0.5rem;
  left: 1rem;
  padding:0.3rem;
}
.share-icon{
  font-size: 1.5rem;
  color:rgb(255, 123, 0);
  transform: translateY(3px);
}
.share-icon:hover{
  cursor: pointer;
}
.view-fullpage:hover{
  background-color: #e8e8e8;
  border-radius: 50%;
}
.status-active{
  position: absolute;
  top:1.4rem;
  right: 1.2rem;
  height:15px;
  width: 15px;
  /* background-color: rgb(247, 16, 16); */
  background-color: rgb(0, 201, 34);
  border-radius: 50%;
}
.status-standby{
  position: absolute;
  top:1.4rem;
  right: 1.2rem;
  height:15px;
  width: 15px;
  /* background-color: rgb(247, 16, 16); */
  background-color: rgb(54, 121, 255);
  border-radius: 50%;
}
.status-released{
  position: absolute;
  top:1.4rem;
  right: 1.2rem;
  height:15px;
  width: 15px;
  /* background-color: rgb(0, 201, 34); */
  background-color: rgb(255, 123, 0);
  border-radius: 50%;
}
.note-link{
  text-decoration: none;
}
.note-link:hover{
  text-decoration: none;
}
.description{
  position: relative;
  /* text-align: left; */
  margin: auto;
  margin-top:40px;
  width:100%;
  height: calc(100% - 90px);
  overflow: hidden;
  transition: 0.2s ease-out;
  margin-bottom:5%;
  transition: 0.2s ease-in;
}

 .description:hover{
  scale: 1.1;
  /* background-color: #dedddd; */
   border-radius: 10%; 

} 
.content{
  color:#000;
  margin: auto;
  padding:5%;
}
/* #text:hover{
  scale: 1.1;
  border-radius: 10%; 
  transition: 0.2s ease-in;
} */
.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.more{
  position: absolute;
  bottom:20px;
  right:0;
  /* color:black; */
}
.note-bottom{
  width:100%;
  margin:auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createdat{
  display: inline-block;
  
}
.btn-transornage{
  background-color: transparent;
  color: rgb(0, 201, 34);
  
  border-color:rgb(0, 201, 34);
  font-weight: 600;
}
.btn-green{
  background-color: rgb(255, 123, 0);
  /* opacity: 100; */
  color:#fff;
  cursor: not-allowed;
}

/* //popup */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 999; /* Ensure overlay appears below the popup */
}
.overlay .bxs-x-circle{
  position: absolute;
  top:0;
  right:0;
  color: #fff;
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  /* Your CSS styles for mobile devices go here */
  .note{
    width:95%;
  }
}

@media screen and (max-width: 767px) {
  .note{
    margin:20px 0 20px 0;
  }
}