.terms{
    width: 60%;
    margin: auto;
}
.terms p, .terms h2{
  text-align: left;
  letter-spacing: 1px;
  font-weight: 600;
}
.terms-ul li{
  text-align: left;
  font-weight:600;
}
.terms h1, .terms h2{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* //refund policy css */
.refund-p{
  color:rgb(255, 123, 0);
  margin-top: 1rem;
}
@media screen and (max-width: 767px) {
    .terms{
      width: 95%;
    }
  }