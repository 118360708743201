.pricing{
    /* padding:5rem; */
    width:60%;
    min-height:100vh;
    height: fit-content;
    margin: auto;
}
.pricing h1{
    margin-top: 5rem;
    font-weight: 700;
}
.pricing p{
    font-size: 1.2rem;
    color:#858585;
}

@media screen and (max-width: 767px) {
    .pricing{
      width: 100%;
      /* margin:0; */
    }
  }