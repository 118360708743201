.notification{
    /* position: absolute;
    bottom:0;
    right:0; */
    display:flex;
    width:100%;
    margin-left:0;
    background-color: rgb(0, 176, 0);
    z-index: 10000;
}
.notification p{
    padding:1rem;
    color:#fff;
    margin-right:2rem;
    display: inline-block;
}
.noti-x{
    position:relative;
    display: inline-block;
    margin-top: 2rem;
}