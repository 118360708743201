/* .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  } */
  
  .popup h2{
    text-align: center;
    font-weight: 800;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .tokenhandler{
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .tokenhandler p{
    padding: 1rem;
    font-size: 1.3rem;
    margin-top: 0.5rem;
  }
  .next-link{
    color:#e8e8e8;
    text-decoration: none;
  }
  .next-link:hover{
    color:#ffffff;
    text-decoration: none;
  }