.signup-container {
    /* display: flex; */
    height: 100%;
    margin: 0;
  }
  .signupquote{
    color:#fff;
    font-size: 6rem;
  }
  
  .empty-space {
    /* flex: 1; */
    background-color: rgb(241, 117, 1);
    
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    height:100%;
    align-items: center;
    z-index: -1;
  }
  .signup-maincontainer{
    /* border:2px solid red; */
    margin-left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 3rem;
  }
  .form-container {
    /* border:2px solid red; */
    padding: 2rem;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    /* align-items: center; */
  }
  .form-container h2{
    font-size: 2.2rem;
    font-weight: 600;
    text-align: left;
    color: #444444;
    margin: auto;
    margin-bottom: 1rem;
  }
  .accountSpan{
    color: rgb(241, 117, 1);
    /* font-size: 4rem; */
  }
  .form-group {
    margin-bottom: 15px;
    text-align: left;
    position: relative;
  }
  .signup-btn{
    width: 100%;
    min-height: 3rem;
    margin-bottom: 2rem;
    background-color: rgb(241, 117, 1);
    color:#fff
  }
 
  label {
    display: block;
    /* font-weight: bold; */
    color: #6d6d6d;
  }
  .form-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
  .form-group input[type="text"],
  .form-group input[type="date"],
  .form-group input[type="email"],
  .form-group input[type="password"] {
    padding: 8px;
    border: none;
    border-bottom: 2px solid rgb(249, 134, 27);
    width: 100%;
  }
  .form-group input[type="password"] {
    font-family: Verdana;
  }
  .form-group input[type="text"]:focus,
  .form-group input[type="date"]:focus,
  .form-group input[type="email"]:focus,
  .form-group input[type="password"]:focus  {
  outline: none; 
  padding: 8px;
  /* border: 3px solid  rgb(255, 129, 11);  */
  /* border-radius: 10px; */
}
.signup-error-text {
  /* border: 3px solid red; */
  color: red;
  font-size: 12px;
  margin: auto;
  text-align: left;
  
}
.signup-divider-line{
    width: 100%;  
    margin: 0 10px;
    border: none;
    border-top: 1px solid #777;
}
.popup-btn-signup{
    width: 100%;
    border: 1px solid #2c2c2c;
    /* height:50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom:2rem;
    transition: box-shadow 0.3s;
}
/* Style for the form group containing the select element */
.form-group {
  position: relative;
  margin-bottom: 20px; /* Adjust margin as needed */
}

/* Style for the select element */
#country {
  width: 100%;
  padding: 8px;
  border: 2px solid rgb(249, 134, 27);
  border-radius: 4px;
  outline: none;
}


/* Style for the error message */
.signup-error-text {
  margin-top: 5px; /* Adjust spacing */
  color: #ff0000; /* Red color for error text */
  font-size: 14px; /* Adjust font size */
}

@media screen and (max-width: 767px) {
  .empty-space {
    display: none;
  }
  .signup-maincontainer {
    width: 100%;
    margin: 0;
    margin-top: 3rem;
  }
  .form-container{
    padding: 0;
    width: 100%;
    margin:0;
  }
}