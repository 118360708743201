/* Footer styles */
.footer-container {
    margin-bottom: 0;
    background-color: #333;
    color: white;
    text-align: center; 
    padding: 2rem; 
    width: 100%;
    z-index: 9999;
  }
  .footer-container p{
    margin-bottom: 4rem;
  }
  
  .footer-links{
    width:80%;
    display:flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin: auto;
  }
  .footer-container-1, .footer-container-2{
    display: flex;
    justify-content: space-between;
  }
  .footer-links a {
    color: white; /* Set the link color to white */
    text-decoration: none; /* Remove the default underline */
    transition: color 0.3s; /* Smooth color transition on hover */
  }
  
  .footer-links a:hover {
    color: #ffffff80; /* Change the link color when hovered (adjust opacity as needed) */
  }
  .footer-link{
    padding:1rem;
  }
  @media screen and (max-width: 767px) {
    
    .footer-links{
      width: 100%;
      margin: 0;
      display: flex;
      /* flex-direction: column; */
    }
    .footer-container-1, .footer-container-2{
      text-align: left;
      display: flex;
      flex-direction: column;
    }
    
    
  }