  .modal-content {
    border: none;
    background-color: #fff;
    padding: 5%;
    width: 100%;
    height:100%;
    margin-left: 0;
    margin-right:10px;
  }

  #mytextarea{
    width: 100%;
    height: 50vh;
    resize: none;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px;
    /* outline: 1px solid rgb(241, 117, 1); Custom outline color when textarea is in focus */
    /* resize: vertical; */
  }
  textarea {
    user-select: text;
  }
  ::selection {
    background-color: rgb(241, 117, 1);
    color: #fff; /* Set your desired text color */
  }
  textarea:focus {
    outline: 3px solid rgb(241, 117, 1); /* Custom outline color when textarea is in focus */
  }
  .quote{
    color:#767676;
    margin-bottom: 3rem;
    text-align: left;
    font-size: 1.5rem;
  }
  .formbtn{
    display: flex;
    justify-content: flex-end;
  }
  .btn-next{
    width: 30%;
    color:rgb(241, 117, 1);
    /* text-align: left; */
  }
  .btn-next:hover{
    color:#fff;
    background-color: rgb(241, 117, 1);
  }
  .btn-orange:hover{
    background-color:rgb(255, 123, 0) ;
  }
  .form-group {
    margin-bottom: 20px;
  }
  
  /* label {
    display: block;
    font-weight: bold;
  } */
  
  
  
  /* button {
    margin-right: 10px;
  } */
  
  .post-navigation{
    display: flex;
    justify-content: space-between;
  }
  .btn-post{
    width:30%;
    color:#fff;
    background-color: rgb(241, 117, 1);
  }
  
  .note-type{
    height:100%;
  }
  .method-radio-container{
    display: flex;
    /* flex-direction: column;  */
    margin-bottom: 2rem;
  }
  .method-radio-container label{
    font-size: 1.3rem;
    font-weight: 500;
  }
  .card{
    max-width: 45%;
    border-radius: 10px;
    margin:1rem;
    cursor: pointer;
    padding:2rem;
  }
  .card-content h3{
    font-weight: 600;
  }
  .selected{
    background-color: #efefef;
  }
  .bx-check{
    color:rgb(241, 117, 1);
    font-weight:600;
  }
  .no-bullets-list {
    list-style-type: none;
    text-align: left;
  }
  .card-content p{
    text-align: left;
  }
  .radio-label{
    display: flex;
  }
  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: rgb(241, 117, 1);
    min-width: 1.15em;
    height: 1.15em;
    border: 0.15em solid rgb(241, 117, 1);
    border-radius: 50%;
    transform: translateY(0.2rem);
    display: grid;
    place-content: center;
    margin-right: 0.5rem;
  }
  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em rgb(241, 117, 1);
  }
  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  .bx-info-circle{
    /* transform: translateY(2px); */
    font-size: 1rem;
  }
  textarea::placeholder{
    color:#b4b4b4;
  }

  @media screen and (max-width: 767px) {
    .card{
      min-width: 100%;
      margin:0;
    }
    .post-navigation{
      margin-bottom:5rem;
    }
    #mytextarea{
      width: 100%;
      height: 8rem;
      resize: none;
      font-family: Arial, sans-serif;
      font-size: 16px;
      color: #333;
      border: 1px solid #ccc;
      border-radius: 20px;
      padding: 10px;
      /* outline: 1px solid rgb(241, 117, 1); Custom outline color when textarea is in focus */
      /* resize: vertical; */
    }
    .note-type{
      margin-top:1rem;
    }
    .quote{
      margin-bottom: 1rem;
    }
    .node-input{
      margin-top:3rem;
    }
  }